import {LocalStorageService} from "./services/localstorage.service";
import {NotsyAutocomlete} from "./components/utlisComponents";
import TextField from "@mui/material/TextField";
import {FormControl, FormLabel, Radio, RadioGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from 'react';

export class SelectTestVariationLogicBranch extends React.Component {

    constructor() {
        super();

        this.state = {
            showTags: true,
            showBranches: false,
            accesses: LocalStorageService.getAccesses()
        }
    }

    componentDidMount() {
        if (this.state.showTags) {
            this.props.handleLogicTypeChange('tag')
        }

        if (this.state.showBranches) {
            this.props.handleLogicTypeChange('branch')
        }
    }

    getSelect() {
        if (this.props.selectUpdate) {
            return <React.Fragment>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p>Logic branch for variation B:</p>

                    <NotsyAutocomlete
                        disablePortal
                        id="logic_branch"
                        options={this.state.showTags ? this.props.tagsNames : this.props.logicBranches}
                        sx={{ width: '70%'}}
                        renderInput={(params) => <TextField {...params} label="Logic branch" />}
                        onChange={(ev, newVal) => this.props.onLogicVersionSelected(newVal)}
                    >

                    </NotsyAutocomlete>
                </div>
            </React.Fragment>
        } else {
            return ''
        }
    }


    getLogicAndTagsChoice() {
        return <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
            >
                <FormControlLabel
                    onChange={() => {
                        this.setState({showTags: true, showBranches: false})
                        this.props.handleLogicTypeChange('tag')
                    }}
                    checked={this.state.showTags}
                    control={<Radio />}
                    label="Tags"
                />
                <FormControlLabel
                    id={'useBranchAsLogicVersion'}
                    onChange={() => {
                        this.setState({showTags: false, showBranches: true})
                        this.props.handleLogicTypeChange('branch')
                    }}
                    checked={this.state.showBranches}
                    control={<Radio />}
                    label="Logic branches"
                />

            </RadioGroup>
        </FormControl>
    }


    render() {
        if (this.props.testVariationData) {
            return (
                <React.Fragment>
                    {
                        !this.props.selectVersionLogicBranch ? (
                            <div>
                                Logic version will be {this.props.testVariationData.version.logic.commitish} / {this.props.testVariationData.version.logic.hash.substring(0, 6)} just as we currently use on production<br />
                            </div>
                        ) : null
                    }

                    <div style={{paddingTop: '10px'}}>
                        {(!this.props.showButtonVersionLogicBranch && this.state.accesses.useLogicBranchInAB) && this.getLogicAndTagsChoice()}
                    </div>

                    {this.props.showButtonVersionLogicBranch ?
                        <button onClick={() => this.props.handlerLogicBranch()} style={{color: '#ffff99', fontSize: 18, border: 'none', background: 'none', textDecoration: 'underline', cursor: 'pointer'}}>
                            {this.props.accesses?.useLogicBranchInAB ? 'Select version tag or logic branch' : 'Update to a higher version'}
                        </button>
                        :
                        null
                    }
                    {
                        this.getSelect()
                    }

                </React.Fragment>
            )
        } else {
            return null
        }
    }
}


export class SelectTestVariationSettingsBranch extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{marginTop: '40px', marginBottom: '25px'}}>Settings branch for variation {this.props.testVariationId}:</p>
                    <NotsyAutocomlete
                        disablePortal
                        id="settings_branch"
                        options={this.props.settingsBranchesNames}
                        sx={{ width: '70%'}}
                        renderInput={(params) => <TextField {...params} label="Settings branch" />}
                        onChange={(ev, newInputValue) => this.props.onSettingsBranchSelected(newInputValue)}
                    >

                    </NotsyAutocomlete>
                </div>
            </React.Fragment>


        )
    }
}