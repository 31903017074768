import TextField from "@mui/material/TextField";
import {NotsyAutocomlete} from "../utlisComponents";
import React  from 'react';

export class SelectLogicBranch extends React.Component {

    constructor(props) {
        super(props);

    }
    render() {
        return (
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <div>Choose logic branch for site {this.props.siteId}</div>
                <NotsyAutocomlete
                    disablePortal
                    id="logic_branch"
                    value={this.props.logicBranchValue}
                    options={(this.props.tagsNames && this.props.branchesNames) ? [...this.props.tagsNames, ...this.props.branchesNames] : []}
                    sx={{ width: '70%', color: 'white' }}
                    onChange={(ev, val) => {
                        this.props.onValueChanged(val)
                    }}
                    renderInput={(params) => <TextField {...params} label="Logic version" />}
                />
                <br />
            </div>
        )
    }
}

export class SelectSettingsBranch extends React.Component {
    render() {
        return (
            <div style={{marginTop: 35, display: 'flex', flexDirection: 'column', alignItems: "center"}}>
                <div>Choose settings branch for site {this.props.siteId}</div>
                <NotsyAutocomlete
                    disablePortal
                    id="settings_branch"
                    options={this.props.branchesNames}
                    sx={{ width: '70%', color: 'white' }}
                    onChange={(ev, newVal) => this.props.onValueChanged(newVal)}
                    renderInput={(params) => <TextField {...params} label="Settings branch" />}
                />
            </div>
        )
    }
}

export class InputDemoName extends React.Component {

    render() {
        const demoNamePattern = '[a-zA-Z0-9_\\-]{3,35}' // eslint-disable-line no-useless-escape

        return (
            <div style={{marginTop: 35}}>
                Choose demo ID for site {this.props.siteId}<br />
                <input onChange={(val) => this.props.onValueChange(val.target.value)} type={'text'} pattern={ demoNamePattern } id="new_demo_name" name="new_demo_name" style={{width: '70%', fontSize: 20}} title="Demo name should only contain english letters, numbers and the following symbols: '_' '-'" required />
                <br />
            </div>
        )
    }
}

export class DemosTableHeader extends React.Component{
    render() {
        return (
            <thead>
                <tr>
                    <td rowSpan={2}>ID</td>
                    <td colSpan={4}>Build info</td>
                    <td rowSpan={2}>Deployed At</td>
                    <td rowSpan={2}>Controls</td>
                </tr>
                <tr>
                    <td>Logic</td>
                    <td>Logic Hash</td>
                    <td>Settings</td>
                    <td>Settings Hash</td>
                </tr>
            </thead>
        );
    }
}